<template>
  <div class="bigSectionBox">
    <p class="titleElement">WOLF PACKS</p>
    <div class="descriptionSection">
      <p class="descriptionElement">
        Stake a set of Wolf, Werewolf and Raven with matching names and form an
        Alpha Pack.
      </p>
      <p class="descriptionElement">
        Alpha Packs will each generate x1.5 more rewards.
      </p>
      <p class="descriptionElement">
        Full Packs will generate x1.2 more rewards.
      </p>
    </div>
    <button
      class="buttonCommon"
      :class="{ disabledButton: !$erdAccount.logged() }"
      @click="showModal"
    >
      <span>STAKE NFT</span>
    </button>
    <br />
    <!-- ALPHA PACKS SECTION -->
    <div class="smallSectionBox" v-if="alphaPacks.length">
      <div class="ribbon ribbon-top-right">
        <span>{{ "X" + alphaMultiplier }}</span>
      </div>
      <p class="smallSectionTitle">ALPHA PACKS</p>
      <span class="separatorSmallSection" />
      <p class="smallSectionCount">
        {{
          alphaPacks.length > 1
            ? alphaPacks.length + " ALPHA PACKS"
            : alphaPacks.length + " ALPHA PACK"
        }}
      </p>
      <div class="NFTlist">
        <div class="stakedCardPackContainer">
          <div
            class="stakedCardRow"
            v-for="(pack, index) in seeMoreAlphaPacks
              ? alphaPacks.slice(0, seeMoreAlphaPacks)
              : alphaPacks"
            :key="index"
          >
            <div v-for="nft in pack" :key="nft.nonce">
              <StakedCard
                :nft="nft"
                @nft-selected="onNftSelected"
                :unstakeArray="unstakeArray"
              ></StakedCard>
            </div>
          </div>
          <p
            class="seeMore"
            v-if="
              seeMoreAlphaPacks < alphaPacks.length && alphaPacks.length > 1
            "
            @click="seeMoreAlphaPacks += 1"
          >
            SEE MORE
          </p>
        </div>
      </div>
    </div>

    <!--  FULL PACKS SECTION -->
    <div class="smallSectionBox" v-if="fullPacks.length">
      <div class="ribbon ribbon-top-right">
        <span>{{ "X" + packMultiplier }}</span>
      </div>
      <p class="smallSectionTitle">FULL PACKS</p>
      <span class="separatorSmallSection" />
      <p class="smallSectionCount">
        {{
          fullPacks.length > 1
            ? fullPacks.length + " FULL PACKS"
            : fullPacks.length + " FULL PACK"
        }}
      </p>
      <div class="NFTlist">
        <div class="stakedCardPackContainer">
          <div
            class="stakedCardRow"
            v-for="(pack, index) in seeMoreFullPacks
              ? fullPacks.slice(0, seeMoreFullPacks)
              : fullPacks"
            :key="index"
          >
            <div v-for="nft in pack" :key="nft.nonce">
              <StakedCard
                :nft="nft"
                @nft-selected="onNftSelected"
                :unstakeArray="unstakeArray"
              ></StakedCard>
            </div>
          </div>
          <p
            class="seeMore"
            v-if="seeMoreFullPacks < fullPacks.length && fullPacks.length > 1"
            @click="seeMoreFullPacks += 1"
          >
            SEE MORE
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import StakedCard from "@/components/generic/StakedCard.vue";
//import type { Nft } from "@/types";

export default {
  components: {
    StakedCard,
  },
  props: {
    unstakeArray: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
    alphaPacks: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
    alphaMultiplier: {
      type: String,
      default() {
        return "0";
      },
    },
    fullPacks: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
    packMultiplier: {
      type: String,
      default() {
        return "0";
      },
    },
  },
  data() {
    return {
      seeMoreFullPacks: 1,
      seeMoreAlphaPacks: 1,
    };
  },
  methods: {
    onNftSelected(nft, isSelected) {
      this.$emit("on-nft-selected", nft, isSelected);
    },
    showModal() {
      this.$emit("show-staking-modal", true);
    },
  },
};
</script>
