<template>
  <div class="bigSectionBox">
    <p class="titleElement">UNBONDING NFTs</p>
    <p class="descriptionElement">
      Each unstaked NFT has to go through a 7 days unbonding period.
    </p>
    <p class="descriptionElement">
      Once the full 7 days pass you can claim back your NFTs.
    </p>

    <!-- UNBONDING SECTION -->
    <div class="smallSectionBox" v-if="unbonding.length">
      <p class="smallSectionTitle">UNBONDING NFTs</p>
      <span class="separatorSmallSection" />
      <p class="smallSectionCount">
        {{
          unbonding.length > 1
            ? unbonding.length + " UNBONDING"
            : unbonding.length + " UNBONDING"
        }}
      </p>
      <div class="NFTlist">
        <div class="stakedCardContainer">
          <UnbondingCard
            v-for="(nft, index) in seeMoreUnbonding
              ? unbonding.slice(0, 3)
              : unbonding"
            :key="nft.nonce"
            :nft="nft"
            :unbondingPeriod="unbondingPeriod"
            :readyToClaim="readyToClaim"
            @nft-claimable="minOneReady"
            v-show="index % 3 === 0 || index % 3 === 1 || index % 3 === 2"
          />
        </div>
        <p
          class="seeMore"
          v-if="seeMoreUnbonding === true && unbonding.length > 3"
          @click="seeMoreUnbonding = false"
        >
          SEE MORE
        </p>
      </div>
    </div>
    <br />
    <button
      class="buttonCommon"
      :disabled="readyToClaim.length < 1"
      :class="{
        disabledButton: readyToClaim.length < 1,
      }"
      @click="claimNfts"
    >
      <span>CLAIM</span>
    </button>
  </div>
</template>

<script lang="js">
import UnbondingCard from "@/components/generic/UnbondingCard.vue";

export default {
  components: {
    UnbondingCard,
  },
  props: {
    unbonding: {
      type: Object,
      default() {
        return {};
      },
    },
    readyToClaim: {
      type: Object,
      default() {
        return {};
      },
    },
    unbondingPeriod: {
      type: Number,
      default: 604800,
    },
  },
  data() {
    return {
      seeMore: true,
      seeMoreUnbonding: true,
      minOneReadyToClaim: false,
    };
  },
  methods: {
    claimNfts(){
      this.$emit("claim-nfts");
    },
    minOneReady(nft){
      this.$emit("nft-claimable", nft);
    },
  },
};
</script>
