<template>
  <button v-if="$erdAccount.address" class="connectButton" @click="logOut()">
    <span>
      {{ $erdAccount.obfuscatedAddress() }}
    </span>
  </button>
  <button v-else class="connectButton" @click="show = true">
    <span>CONNECT</span>
  </button>
  <transition name="fade">
    <div class="modalShadow" v-if="show" @click.self="show = false">
      <div class="modalLogin" @click.stop>
        <div class="modalHeader">
          <div class="closeButton" @click="show = false">
            <img src="@/assets/close.svg" alt="Close" />
          </div>
        </div>
        <VueErdjsConnect
          :qrcodeHandler="qrcodeHandler"
          :token="'hello'"
          @click="clickOnWalletConnect"
        />
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
@import "./login.scss";
</style>

<script setup>
import { VueErdjsConnect } from "vue-mvx";
import { sleep } from "@/utils";
import { useVueErd } from "vue-mvx";
import { onMounted, ref, onBeforeMount, watch } from "vue";
import CustomQRCodeHandler from "../../components/qrcode/CustomQRCodeHandler";

const qrcodeHandler = new CustomQRCodeHandler();

const { account, erd } = useVueErd();
const show = ref(false);

const clickOnWalletConnect = () => {
  setTimeout(() => {
    const linkWrapperElAlreadyAppenned = document.querySelector(
      ".vue3rdj5__modes-open__btn-login-maiar-wrapper"
    );
    if (linkWrapperElAlreadyAppenned) return;
    const qrCodeEl = document.querySelector(".vue3rdj5__mode-qr");
    if (!qrCodeEl) return;
    const qrCodeContent = qrCodeEl["data-qrCode-data"];
    if (!qrCodeContent) return;
    const baseUrl =
      "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/?wallet-connect=";
    const maiarAppLink = baseUrl + encodeURIComponent(qrCodeContent);
    const linkEl = document.createElement("a");
    linkEl.href = maiarAppLink;
    linkEl.innerText = "Maiar login";
    linkEl.target = "_blank";
    const linkWrapperEl = document.createElement("div");
    linkWrapperEl.classList.add(
      "vue3rdj5__modes-open__btn-login-maiar-wrapper"
    );
    linkWrapperEl.appendChild(linkEl);
    const parentEl = qrCodeEl.parentElement;
    parentEl.appendChild(linkWrapperEl);
  }, 200);
};

watch([show, account], ([newShow, newAccount]) => {
  if (newShow && newAccount && newAccount.logged()) {
    show.value = false;
  }
});

onMounted(async () => {
  if (account.logged() && show) {
    show.value = false;
  }
});

onBeforeMount(async () => {
  if (account.logged() && show) {
    show.value = false;
  }
  let waiting = 5;
  while (!account.logged() && waiting != 0) {
    await sleep(500);
    waiting -= 0.5;
  }
});

function logOut() {
  if (account.logged() && show) {
    return erd.logout();
  }
}
</script>
