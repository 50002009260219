<template>
  <transition name="fade">
    <div class="modalShadowStake" v-if="show" @click.self="hide()">
      <div class="modalStake" @click.stop>
        <div class="modalHeaderStake">
          <div class="closeButton" @click="hide()">
            <img src="@/assets/close.svg" alt="Close" />
          </div>
        </div>
        <div class="bigModalBox">
          <div
            v-if="
              balance.wolves.length +
                balance.werewolves.length +
                balance.ravens.length +
                balance.ultra.length +
                balance.bones.length <
              1
            "
            class="sections"
          >
            <p class="titleElement">BUY NFTS AND STAKE THEM</p>
            <div class="descriptionSection">
              <p class="descriptionElement">
                Staking individual NFTs will generate a set rate of rewards per
                NFT.
              </p>
              <p class="descriptionElement">
                Your NFTs will be automatically sorted into Individual NFTs,
                Alpha Packs and Full Packs.
              </p>
              <p class="descriptionElement">
                Staked Golden Bones apply a multiplier of x1.4 on all staked
                NFTs rewards.
              </p>
            </div>
            <button
              role="link"
              class="buttonCommon"
              @click="openInNewTab('https://linktr.ee/hsws')"
              target="blank"
            >
              <span>BUY NFTs</span>
            </button>
          </div>
          <div v-else class="sections">
            <p class="titleElement">INDIVIDUAL NFTs</p>
            <div class="descriptionSection">
              <p class="descriptionElement">
                Staking individual NFTs will generate a set rate of rewards per
                NFT.
              </p>
              <p class="descriptionElement">
                Your NFTs will be automatically sorted into Individual NFTs,
                Alpha Packs and Full Packs.
              </p>
              <p class="descriptionElement">
                Staked Golden Bones apply a multiplier of x1.4 on all staked
                NFTs rewards.
              </p>
            </div>
          </div>
          <div class="sections">
            <!-- WOLF SECTION -->
            <div class="smallSectionBox" v-if="balance.wolves.length">
              <p class="smallSectionTitle">HIGH STREET WOLF</p>
              <span class="separatorSmallSection"></span>
              <p class="smallSectionCount">
                {{
                  balance.wolves.length > 1
                    ? balance.wolves.length + " WOLVES"
                    : balance.wolves.length + " WOLF"
                }}
              </p>
              <div class="NFTlist">
                <div class="stakedCardContainer">
                  <BalanceCard
                    v-for="nft in seeMoreWolves
                      ? balance.wolves.slice(0, seeMoreWolves)
                      : balance.wolves"
                    :key="nft.nonce"
                    :nft="nft"
                    @nft-selected="addStaking"
                    @nft-unselected="removeStaking"
                  />
                </div>
                <p
                  class="seeMore"
                  v-if="seeMoreWolves < balance.wolves.length"
                  @click="seeMoreWolves += 3"
                >
                  SEE MORE
                </p>
              </div>
            </div>

            <!-- WEREWOLF SECTION -->
            <div class="smallSectionBox" v-if="balance.werewolves.length">
              <p class="smallSectionTitle">HIGH STREET WEREWOLF</p>
              <span class="separatorSmallSection"></span>
              <p class="smallSectionCount">
                {{
                  balance.werewolves.length > 1
                    ? balance.werewolves.length + " WEREWOLVES"
                    : balance.werewolves.length + " WEREWOLF"
                }}
              </p>
              <div class="NFTlist">
                <div class="stakedCardContainer">
                  <BalanceCard
                    v-for="nft in seeMoreWerewolves
                      ? balance.werewolves.slice(0, seeMoreWerewolves)
                      : balance.werewolves"
                    :key="nft.nonce"
                    :nft="nft"
                    @nft-selected="addStaking"
                    @nft-unselected="removeStaking"
                  />
                </div>
                <p
                  class="seeMore"
                  v-if="seeMoreWerewolves < balance.werewolves.length"
                  @click="seeMoreWerewolves += 3"
                >
                  SEE MORE
                </p>
              </div>
            </div>

            <!-- RAVEN SECTION -->
            <div class="smallSectionBox" v-if="balance.ravens.length">
              <p class="smallSectionTitle">HIGH STREET RAVEN</p>
              <span class="separatorSmallSection"></span>
              <p class="smallSectionCount">
                {{
                  balance.ravens.length > 1
                    ? balance.ravens.length + " RAVENS"
                    : balance.ravens.length + " RAVEN"
                }}
              </p>
              <div class="NFTlist">
                <div class="stakedCardContainer">
                  <BalanceCard
                    v-for="nft in seeMoreRavens
                      ? balance.ravens.slice(0, seeMoreRavens)
                      : balance.ravens"
                    :key="nft.nonce"
                    :nft="nft"
                    @nft-selected="addStaking"
                    @nft-unselected="removeStaking"
                  />
                </div>
                <p
                  class="seeMore"
                  v-if="seeMoreRavens < balance.ravens.length"
                  @click="seeMoreRavens += 3"
                >
                  SEE MORE
                </p>
              </div>
            </div>

            <!-- GOLDEN BONE SECTION -->
            <div
              class="smallSectionBox"
              v-if="balance.bones.length && bones.length < 1"
            >
              <p class="smallSectionTitle">GOLDEN BONES</p>
              <span class="separatorSmallSection"></span>
              <p class="smallSectionCount">
                {{
                  parseInt(
                    balance.bones[0].nft_type.name.substring(
                      1,
                      balance.bones[0].nft_type.name.length
                    )
                  ) > 1
                    ? balance.bones[0].nft_type.name.substring(
                        1,
                        balance.bones[0].nft_type.name.length
                      ) + " BONES"
                    : balance.bones[0].nft_type.name.substring(
                        1,
                        balance.bones[0].nft_type.name.length
                      ) + " BONE"
                }}
              </p>
              <div class="NFTlist">
                <div class="stakedCardContainer">
                  <BalanceCard
                    :nft="balance.bones[0]"
                    @nft-selected="addStaking"
                    @nft-unselected="removeStaking"
                  />
                </div>
              </div>
            </div>

            <br />
            <button
              v-if="stakingSelection.length > 0"
              class="buttonCommon"
              :class="{
                disabledButton: !$erdAccount.logged() || alreadyClicked,
              }"
              @click="stakeNfts"
            >
              <span>STAKE NFTs</span>
            </button>
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
@import "./stakingModal.scss";
</style>

<script lang="js">
import { VueErdjsConnect } from "vue-mvx";
import { sleep } from "@/utils";
import { useVueErd } from "vue-mvx";
import {
  onMounted,
  ref,
  onBeforeMount,
  watch,
  toRef,
  defineProps,
  defineEmits,
  emit,
} from "vue";
import BalanceCard from "@/components/generic/BalanceCard.vue";
import useStoreStaking from "@/store/staking";
import { ITransactionOnNetwork } from "@multiversx/sdk-core/out";

export default {
  components: { BalanceCard },
  props: {
    balance: {
      type: Object,// as () => Balance,
      required: true,
    },
    bones: {
      type: Object,// as () => Balance,
      required: false,
    },
    showprop: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, {emit}) {
    const storeStaking = useStoreStaking();
    const { account, erd } = useVueErd();
    const seeMoreWolves = ref(3); // switch for button
    const seeMoreWerewolves = ref(3);
    const seeMoreRavens = ref(3);
    const stakingSelection = ref([]);
    const alreadyClicked = ref(false);

    function addStaking(nft) {
      stakingSelection.value.push(nft);
    }

    function removeStaking(nft) {
      const index = stakingSelection.value.findIndex(
        (item) => item === nft
      );
      if (index !== -1) {
        stakingSelection.value.splice(index, 1);
      }
    }

    async function stakeNfts() {
       alreadyClicked.value = true;
       console.log("clicked", alreadyClicked.value);
       alreadyClicked.value = await storeStaking.stake(stakingSelection.value);
       if (alreadyClicked.value == true) {
        alreadyClicked.value = false;
        show.value = false;
       }
    }

    const show = ref(false);
    const showprop = toRef(props, "showprop");
    const balance = toRef(props, "balance");

    watch(showprop, (value) => {
      show.value = showprop.value;
    });

    watch(show, (value) => {
      if (show.value === false) {
        emit("close-staking-modal", false);
      }
    });

    function openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    }

    function hide() {
      show.value = false;
      seeMoreWolves.value = 3;
      seeMoreWerewolves.value = 3;
      seeMoreRavens.value = 3;
      stakingSelection.value = [];
    }

    return {
      seeMoreWolves,
      seeMoreWerewolves,
      seeMoreRavens,
      stakingSelection,
      addStaking,
      removeStaking,
      stakeNfts,
      show,
      hide,
      openInNewTab,
      alreadyClicked,
    };
  },
};
</script>

<style scoped>
.sections {
  text-align: center;
  display: block;
}
.bigModalBox {
  z-index: 1;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modalStake {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000000;
  margin: auto;
  max-height: 90%;
  max-width: 80%;
  overflow-y: auto;
  padding: 2%;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(
        150.43deg,
        #a0a0a0 -13.16%,
        #343337 26.07%,
        #343337 67.66%,
        #a0a0a0 117.25%
      )
      border-box;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 25px;
}

.modalHeaderStake::after,
.modalStake::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  pointer-events: none;
}

.modalStake::after {
  z-index: 2;
  background: linear-gradient(transparent 21px, #1e1e1e);
  bottom: 21px;
}

.modalHeaderStake::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 2.5rem;
  height: 100px;
  z-index: 2;
  background: linear-gradient(#1e1e1e, transparent 21px);
}

.modalShadowStake {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
  background-color: #80808048;
}
.modalHeaderStake {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2.5rem;
}

.closeButton {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}
</style>
