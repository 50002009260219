
import Login from "@/modal/login/login.vue";
export default {
  data() {
    return {
      isDesktop: window.innerWidth >= 1024,
    };
  },
  components: {
    Login,
  },
};
