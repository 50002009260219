export default {
  width: 304,
  height: 304,
  data: "https://qr-code-styling.com",
  margin: 0,
  qrOptions: {
    typeNumber: "0",
    mode: "Byte",
    errorCorrectionLevel: "Q",
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 0,
  },
  dotsOptions: {
    type: "classy-rounded",
    color: "#6a1a4c",
    gradient: {
      type: "radial",
      rotation: 0,
      colorStops: [
        {
          offset: 0,
          color: "#757575",
        },
        {
          offset: 1,
          color: "#000000",
        },
      ],
    },
  },
  backgroundOptions: {
    color: "#ffffff",
  },
  image: "10cc19bd484118dbcd0a7886a38ceddc.png",
  dotsOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: "#6a1a4c",
      color2: "#6a1a4c",
      rotation: "0",
    },
  },
  cornersSquareOptions: {
    type: "",
    color: "#000000",
    gradient: {
      type: "linear",
      rotation: 0,
      colorStops: [
        {
          offset: 0,
          color: "#6b6b6b",
        },
        {
          offset: 1,
          color: "#000000",
        },
      ],
    },
  },
  cornersSquareOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: "#000000",
      color2: "#000000",
      rotation: "0",
    },
  },
  cornersDotOptions: {
    type: "",
    gradient: {
      type: "linear",
      rotation: 0,
      colorStops: [
        {
          offset: 0,
          color: "#7a7a7a",
        },
        {
          offset: 1,
          color: "#000000",
        },
      ],
    },
  },
  cornersDotOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: "#000000",
      color2: "#000000",
      rotation: "0",
    },
  },
  backgroundOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: "#ffffff",
      color2: "#ffffff",
      rotation: "0",
    },
  },
};
