<template>
  <div class="bigSectionBox">
    <div v-if="bones.length >= 1" class="ribbon ribbon-top-right">
      <span>{{ "X" + multiplier }}</span>
    </div>
    <p class="titleElement">INDIVIDUAL NFTs</p>
    <div class="descriptionSection">
      <p class="descriptionElement">
        Staking individual NFTs will generate a set rate of rewards per NFT.
      </p>
      <p class="descriptionElement">
        Staked Golden Bones apply a multiplier of x1.4 on all staked NFTs
        rewards.
      </p>
    </div>

    <button
      class="buttonCommon"
      :class="{ disabledButton: !$erdAccount.logged() }"
      @click="showModal"
    >
      <span>STAKE NFTs</span>
    </button>
    <br />

    <!-- WOLF SECTION -->
    <div class="smallSectionBox" v-if="wolves.length">
      <p class="smallSectionTitle">HIGH STREET WOLF</p>
      <span class="separatorSmallSection" />
      <p class="smallSectionCount">
        {{
          wolves.length > 1
            ? wolves.length + " WOLVES"
            : wolves.length + " WOLF"
        }}
      </p>
      <div class="NFTlist">
        <div class="stakedCardContainer">
          <StakedCard
            v-for="(nft, index) in seeMoreWolves ? wolves.slice(0, 3) : wolves"
            :key="nft.nonce"
            :nft="nft"
            :unstakeArray="unstakeArray"
            @nft-selected="onNftSelected"
            v-show="index % 3 === 0 || index % 3 === 1 || index % 3 === 2"
          />
        </div>
        <p
          class="seeMore"
          v-if="seeMoreWolves === true && wolves.length > 3"
          @click="seeMoreWolves = false"
        >
          SEE MORE
        </p>
      </div>
    </div>

    <!--  WEREWOLF SECTION -->
    <div class="smallSectionBox" v-if="werewolves.length">
      <p class="smallSectionTitle">HIGH STREET WEREWOLF</p>
      <span class="separatorSmallSection" />
      <p class="smallSectionCount">
        {{
          werewolves.length > 1
            ? werewolves.length + " WEREWOLVES"
            : werewolves.length + " WEREWOLF"
        }}
      </p>
      <div class="NFTlist">
        <div class="stakedCardContainer">
          <StakedCard
            v-for="(nft, index) in seeMoreWerewolves
              ? werewolves.slice(0, 3)
              : werewolves"
            :key="nft.nonce"
            :nft="nft"
            :unstakeArray="unstakeArray"
            @nft-selected="onNftSelected"
            v-show="index % 3 === 0 || index % 3 === 1 || index % 3 === 2"
          ></StakedCard>
        </div>
        <p
          class="seeMore"
          v-if="seeMoreWerewolves === true && werewolves.length > 3"
          @click="seeMoreWerewolves = false"
        >
          SEE MORE
        </p>
      </div>
    </div>

    <!-- RAVEN SECTION -->
    <div class="smallSectionBox" v-if="ravens.length">
      <p class="smallSectionTitle">HIGH STREET RAVEN</p>
      <span class="separatorSmallSection" />
      <p class="smallSectionCount">
        {{
          ravens.length > 1
            ? ravens.length + " RAVENS"
            : ravens.length + " RAVEN"
        }}
      </p>
      <div class="NFTlist">
        <StakedCard
          v-for="nft in seeMoreRavens ? ravens.slice(0, 3) : ravens"
          :key="nft.nonce"
          :nft="nft"
          :unstakeArray="unstakeArray"
          @nft-selected="onNftSelected"
        ></StakedCard>
        <p
          class="seeMore"
          v-if="seeMoreRavens === true && ravens.length > 3"
          @click="seeMoreRavens = false"
        >
          SEE MORE
        </p>
      </div>
    </div>

    <!-- GOLDEN BONES SECTION -->
    <div class="smallSectionBox" v-if="bones.length >= 1">
      <p class="smallSectionTitle">GOLDEN BONES</p>
      <span class="separatorSmallSection" />
      <p class="smallSectionCount">
        {{ bones.length + " GOLDEN  BONE" }}
      </p>
      <div class="NFTlist">
        <StakedCard
          :nft="bones[0]"
          :unstakeArray="unstakeArray"
          @nft-selected="onNftSelected"
        ></StakedCard>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import StakedCard from "@/components/generic/StakedCard.vue";
//import { Nft, NftArray } from "@/types";

export default {
  components: {
    StakedCard,
  },
  props: {
    nftArray: {
      type: Object,// as () => NftArray,
      default() {
        return { wolves: [], werewolves: [], ravens: [], bones: [] };
      },
    },
    unstakeArray: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
    wolves: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
    werewolves: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
    ravens: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
    bones: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
    multiplier: {
      type: String,
      default() {
        return "0";
      },
    },
  },
  data() {
    return {
      seeMoreWolves: true, // switch for button
      seeMoreWerewolves: true,
      seeMoreRavens: true,
    };
  },
  methods: {
    onNftSelected(nft, isSelected) {
      this.$emit("on-nft-selected", nft, isSelected);
    },
    showModal() {
      this.$emit("show-staking-modal", true);
    },
  },
};
</script>
