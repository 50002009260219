<template>
  <div class="bigSectionBox">
    <p class="titleElement">NFTS TO UNSTAKE</p>
    <p class="descriptionElement">
      Each unstaked NFT has to go through a 7 day unbonding period.
    </p>
    <p class="descriptionElement">
      Once the full 7 days pass you can claim back your NFTs.
    </p>

    <!-- UNBONDING SECTION -->
    <div class="smallSectionBox" v-if="unstakeArray.length">
      <p class="smallSectionTitle">NFTS TO UNSTAKE</p>
      <span class="separatorSmallSection" />
      <p class="smallSectionCount">
        {{
          unstakeArray.length > 1
            ? "UNSTAKING " + unstakeArray.length + " NFTs"
            : "UNSTAKING " + unstakeArray.length + " NFT"
        }}
      </p>
      <div class="NFTlist">
        <div class="stakedCardContainer">
          <UnstakeCard
            v-for="nft in unstakeArray"
            :key="nft.nonce"
            :nft="nft"
            @nft-unstake-selected="onNftUnselected"
          ></UnstakeCard>
        </div>
      </div>
    </div>
    <br />
    <button
      class="buttonCommon"
      :disabled="unstakeArray?.length < 1"
      :class="{ disabledButton: unstakeArray?.length < 1 }"
      @click="showUnstakeModal"
    >
      <span>UNSTAKE</span>
    </button>

    <!-- Unstake Confirmation Modal -->
    <div v-if="showModal" class="modal" @click="hideUnstakeModal">
      <div class="modal-content" @click.stop>
        <div class="modalHeader">
          <div class="closeButton" @click="showModal = false">
            <img src="@/assets/close.svg" alt="Close" />
          </div>
        </div>
        <p class="smallSectionTitle">⚠ WARNING ⚠</p>
        <p>
          Unstaking an NFT takes 7 days.
          <br />
          Are you sure you want to unstake your NFTs?
        </p>
        <div>
          <button class="buttonCommon" @click="unstakeNFTs">
            <span>YES</span>
          </button>
          <button class="buttonCommon" @click="hideUnstakeModal">
            <span>NO</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import UnstakeCard from "@/components/generic/UnstakeCard.vue";

export default {
  components: {
    UnstakeCard,
  },
  props: {
    unstakeArray: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    showUnstakeModal() {
      this.showModal = true;
    },
    hideUnstakeModal() {
      this.showModal = false;
    },
    unstakeNFTs() {
      this.showModal = false;
      this.$emit("unstake-nfts");
    },
    onNftUnselected(nft, isUnselected) {
      this.$emit("on-nft-unselected", nft, isUnselected);
    },
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  text-align: center;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(
        150.43deg,
        #a0a0a0 -13.16%,
        #343337 26.07%,
        #343337 67.66%,
        #a0a0a0 117.25%
      )
      border-box;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 25px;
  padding-bottom: 60px;
}

button {
  margin: 10px;
}
</style>
