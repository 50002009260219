import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixins from "./mixins";
import { createPinia } from "pinia";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { ElrondEnvEnum, providersOptions, initVueErdJsPlugin } from "vue-mvx";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";

const app = createApp(App);
const pinia = createPinia();

initVueErdJsPlugin(
  providersOptions(ElrondEnvEnum.MAINNET, "ea31ed0732403492e1cca6a3af74683c")
).then((vueErdJsPlugin) => {
  app.use(vueErdJsPlugin);
  app.use(router);
  app.use(store);
  app.use(pinia);
  app.mixin(mixins);
  app.use(Vue3Toastify, {
    autoClose: 3000,
  } as ToastContainerOptions);
  app.mount("#app");
});
