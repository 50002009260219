import { defineStore } from "pinia";
import axios from "axios";
import { ref, onMounted } from "vue";
import { useVueErd } from "vue-mvx";
import {
  TokenPayment,
  Transaction,
  TransactionPayload,
  AbiRegistry,
  ResultsParser,
  SmartContract,
  ContractFunction,
  AddressValue,
  Address,
  U64Value,
  TokenIdentifierValue,
} from "@multiversx/sdk-core";
import abi_json from "@/assets/ABI/hsws-staking.abi.json";
import type { Account, ITransactionOnNetwork } from "@multiversx/sdk-core";
import { intToHex, sleep, strToHex } from "@/ts/utils";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import type {
  Nft,
  IndividualNfts,
  Balance,
  UnbondingNft,
  IndividualForPacks,
} from "@/types";
import Msg from "@/store/Msg.vue";
import type { Id } from "vue3-toastify";

const useStoreStaking = defineStore("staking", () => {
  const { account, erd, fetchAccount } = useVueErd();
  const contract = ref<SmartContract>();
  const abi = ref();
  const contract_address =
    "erd1qqqqqqqqqqqqqpgqamumm7yp96t92yssxejzf9jkdum6a4vxutsqy0ddsg";
  const sender_account = ref<Account>();
  // Staked nfts
  const full_packs = ref<Nft[][]>([]);
  const full_packs_list = ref<Nft[]>([]);
  const alpha_names = ref();
  const alpha_packs = ref<Nft[][]>([]);
  const active_toast = ref();
  const toast_info = ref();
  const individual_nfts = ref<IndividualNfts>({
    wolves: [],
    werewolves: [],
    ravens: [],
    ultra: [],
    bones: [],
  });
  const individual_for_packs = ref<IndividualForPacks>({
    og: [],
    wolf: [],
    l1: [],
    l2: [],
    raven: [],
    ultra: [],
  });

  const user_balance = ref<Balance>({
    wolves: [],
    werewolves: [],
    ravens: [],
    ultra: [],
    bones: [],
    tokens: "0",
  });

  const golden_bones = ref();
  const actual_period = 86400;
  const mainnet_api = "https://api.multiversx.com";
  // Unstaked Nfts
  const unstakeArray = ref<Nft[]>([]);

  // Staking info
  const reward_period = ref();
  const rewards = ref();
  const unbonding_period = ref();
  const unbonding = ref<UnbondingNft[]>([]);
  const ready_to_claim = ref<UnbondingNft[]>([]);
  const multipliers = ref({
    individual: "",
    alpha: "",
    pack: "",
    bone: "",
  });

  const tokenIdentifiers = ref({
    wolves: "",
    werewolves: "",
    ravens: "",
    bone: "",
    token: "",
  });

  const reward_rates = ref({
    og: "",
    wolf: "",
    l1: "",
    l2: "",
    ultra: "",
    raven: "",
  });

  const abiRegistry = ref();

  const earning = ref();
  const earned = ref();
  // Transaction info
  const transactionState = ref();
  const transactionResult = ref();
  const transactionUrl = ref();
  const toast_id = ref<Id>();
  const global_stats = ref({
    wolves: "0",
    werewolves: "0",
    ravens: "0",
    tokens: "0",
  });
  onMounted(async () => {
    erd.on("transaction", async (transaction: ITransactionOnNetwork) => {
      transactionUrl.value =
        "https://explorer.elrond.com/transactions/" + transaction.hash;
      let returnCode = null;
      try {
        returnCode = await new ResultsParser().parseUntypedOutcome(transaction);
      } catch (e) {
        console.log(e);
      }
      if (returnCode != null && returnCode.returnCode.isSuccess()) {
        toast_info.value = {
          title: "Transaction success!",
          message: "Your transaction was processed.",
          status: returnCode.returnCode.isSuccess(),
          link: transactionUrl.value,
        };
        toast.update(toast_id.value!, {
          autoClose: 20000,
          type: "success",
          isLoading: false,
        });
        reset();
      } else if (returnCode != null) {
        toast_info.value = {
          title: "Transaction failed!",
          message: returnCode.returnMessage,
          status: returnCode.returnCode.isSuccess(),
          link: transactionUrl.value,
        };
        toast.update(toast_id.value!, {
          autoClose: 20000,
          type: "error",
          isLoading: false,
        });
      } else {
        toast_info.value = {
          title: "Something Happend!",
          message: "Check your transaction.",
          status: null,
          link: transactionUrl.value,
        };
        toast.update(toast_id.value!, {
          autoClose: 20000,
          type: "info",
          isLoading: false,
        });
      }
      sleep(3000);
      reset();
    });
    sender_account.value = await fetchAccount();
  });

  const init = async () => {
    sender_account.value = await fetchAccount();
    erd.proxy.config.timeout = 15000;
    unstakeArray.value = [];
    setContract();
    await getEssentials();
    // General staking info
    getUnbondingPeriod();
    getRewards();
    getEarnings();
    await getBalance();
  };

  async function getEssentials() {
    getMultipliers();
    getRewardRates();
    await getAcceptedTokens();
    // User info
    // User staking info
    await getGoldenBones();
    await getAlphaPacks();
    await getIndividual();
    await getFullPacks();
    await getUnbonding();
    await getGlobalStats();
    getRewardPeriod();
  }
  const wipe = () => {
    contract.value = undefined;
    abi.value = undefined;
    sender_account.value = undefined;
    full_packs.value = [];
    full_packs_list.value = [];
    alpha_names.value = undefined;
    alpha_packs.value = [];
    individual_nfts.value = {
      wolves: [],
      werewolves: [],
      ravens: [],
      ultra: [],
      bones: [],
    };
    user_balance.value = {
      wolves: [],
      werewolves: [],
      ravens: [],
      ultra: [],
      bones: [],
      tokens: "0",
    };
    golden_bones.value = 0;
    reward_period.value = undefined;
    rewards.value = undefined;
    unbonding_period.value = undefined;
    unbonding.value = [];
    ready_to_claim.value = [];
    multipliers.value = {
      individual: "",
      alpha: "",
      pack: "",
      bone: "",
    };
    tokenIdentifiers.value = {
      wolves: "",
      werewolves: "",
      ravens: "",
      bone: "",
      token: "",
    };
    reward_rates.value = {
      og: "",
      wolf: "",
      l1: "",
      l2: "",
      ultra: "",
      raven: "",
    };
    unstakeArray.value = [];
    earning.value = undefined;
    transactionState.value = undefined;
    transactionResult.value = undefined;
    transactionUrl.value = undefined;
  };

  const reset = async () => {
    wipe();
    await init();
  };

  const setContract = () => {
    if (abi.value == null) {
      abiRegistry.value = AbiRegistry.create(abi_json);
    }
    contract.value = new SmartContract({
      address: new Address(contract_address),
      abi: abiRegistry.value,
    });
  };

  function periods_passed(nft: any) {
    const current_timestamp = Math.floor(Date.now() / 1000);
    return Math.floor(
      (current_timestamp - nft.last_claim) / reward_period.value
    );
  }
  function getEarnings() {
    let tot = 0;
    let indiv = 0;
    let real_rewards = 0;
    const reward_period_to_mult = 1;
    let rewards = 0;
    const og = parseFloat(reward_rates.value.og);
    const og_pack = parseFloat(
      (og * parseFloat(multipliers.value.pack)).toFixed(3)
    );
    const og_alpha = parseFloat(
      (og * parseFloat(multipliers.value.alpha)).toFixed(3)
    );
    const wolf = parseFloat(reward_rates.value.wolf);
    const wolf_pack = parseFloat(
      (wolf * parseFloat(multipliers.value.pack)).toFixed(3)
    );
    const wolf_alpha = parseFloat(
      (wolf * parseFloat(multipliers.value.alpha)).toFixed(3)
    );
    const l1 = parseFloat(reward_rates.value.l1);
    const l1_pack = parseFloat(
      (l1 * parseFloat(multipliers.value.pack)).toFixed(3)
    );
    const l1_alpha = parseFloat(
      (l1 * parseFloat(multipliers.value.alpha)).toFixed(2)
    );
    const l2 = parseFloat(reward_rates.value.l2);
    const l2_pack = parseFloat(
      (l2 * parseFloat(multipliers.value.pack)).toFixed(3)
    );
    const l2_alpha = parseFloat(
      (l2 * parseFloat(multipliers.value.alpha)).toFixed(3)
    );
    const ultra = parseFloat(reward_rates.value.ultra);
    const raven = parseFloat(reward_rates.value.raven);
    const raven_pack = parseFloat(
      (raven * parseFloat(multipliers.value.pack)).toFixed(3)
    );
    const raven_alpha = parseFloat(
      (raven * parseFloat(multipliers.value.alpha)).toFixed(2)
    );

    individual_nfts.value.wolves.forEach((nft) => {
      if (nft.nft_type.name === "Og") {
        rewards += og * reward_period_to_mult;
        real_rewards += og * periods_passed(nft);
        indiv += 1;
        tot += 1;
      } else if (nft.nft_type.name === "Wolf") {
        rewards += wolf * reward_period_to_mult;
        real_rewards += wolf * periods_passed(nft);
        indiv += 1;
        tot += 1;
      }
    });

    individual_nfts.value.werewolves.forEach((nft) => {
      if (nft.nft_type.name === "L1") {
        rewards += l1 * reward_period_to_mult;
        real_rewards += l1 * periods_passed(nft);
        indiv += 1;
        tot += 1;
      } else if (nft.nft_type.name === "L2") {
        rewards += l2 * reward_period_to_mult;
        real_rewards += l2 * periods_passed(nft);
        indiv += 1;
        tot += 1;
      } else if (nft.nft_type.name === "Ultra") {
        rewards += ultra * reward_period_to_mult;
        real_rewards += ultra * periods_passed(nft);
        indiv += 1;
        tot += 1;
      }
    });

    individual_nfts.value.ravens.forEach((nft) => {
      rewards += raven * reward_period_to_mult;
      real_rewards += raven * periods_passed(nft);
      indiv += 1;
      tot += 1;
    });

    full_packs_list.value.forEach((nft) => {
      if (nft.nft_type.name === "L1") {
        rewards += l1_pack * reward_period_to_mult;
        real_rewards += l1_pack * periods_passed(nft);
        tot += 1;
      } else if (nft.nft_type.name === "L2") {
        rewards += l2_pack * reward_period_to_mult;
        real_rewards += l2_pack * periods_passed(nft);
        tot += 1;
      } else if (nft.nft_type.name === "Ultra") {
        rewards += ultra * reward_period_to_mult;
        real_rewards += ultra * periods_passed(nft);
        tot += 1;
      } else if (nft.nft_type.name === "Og") {
        rewards += og_pack * reward_period_to_mult;
        real_rewards += og_pack * periods_passed(nft);
        tot += 1;
      } else if (nft.nft_type.name === "Wolf") {
        rewards += wolf_pack * reward_period_to_mult;
        real_rewards += wolf_pack * periods_passed(nft);
        tot += 1;
      } else if (nft.nft_type.name === "Raven") {
        rewards += raven_pack * reward_period_to_mult;
        real_rewards += raven_pack * periods_passed(nft);
        tot += 1;
      }
    });

    alpha_packs.value.forEach((pack) => {
      pack.forEach((nft) => {
        if (nft.nft_type.name === "L1") {
          rewards += l1_alpha * reward_period_to_mult;
          real_rewards += l1_alpha * periods_passed(nft);
          tot += 1;
        } else if (nft.nft_type.name === "L2") {
          rewards += l2_alpha * reward_period_to_mult;
          real_rewards += l2_alpha * periods_passed(nft);
          tot += 1;
        } else if (nft.nft_type.name === "Ultra") {
          rewards += ultra * reward_period_to_mult;
          real_rewards += ultra * periods_passed(nft);
          tot += 1;
        } else if (nft.nft_type.name === "Og") {
          rewards += og_alpha * reward_period_to_mult;
          real_rewards += og_alpha * periods_passed(nft);
          tot += 1;
        } else if (nft.nft_type.name === "Wolf") {
          rewards += wolf_alpha * reward_period_to_mult;
          real_rewards += wolf_alpha * periods_passed(nft);
          tot += 1;
        } else if (nft.nft_type.name === "Raven") {
          rewards += raven_alpha * reward_period_to_mult;
          real_rewards += raven_alpha * periods_passed(nft);
          tot += 1;
        }
      });
    });
    if (individual_nfts.value.bones.length >= 1) {
      rewards = rewards * parseFloat(multipliers.value.bone);
      real_rewards = real_rewards * parseFloat(multipliers.value.bone);
    }
    earning.value = rewards.toFixed(2);
    console.log("Calculated earned rewards:", real_rewards);
    return rewards.toFixed(2);
  }

  function groupNftsByType(nfts: Nft[]): [Nft, Nft, Nft][] {
    const werewolves: Nft[] = [];
    const wolves: Nft[] = [];
    const ravens: Nft[] = [];

    for (const nft of nfts) {
      switch (nft.nft_type.name) {
        case "Wolf":
        case "Og":
          wolves.push(nft);
          break;
        case "L1":
        case "L2":
          werewolves.push(nft);
          break;
        case "Raven":
          ravens.push(nft);
          break;
        default:
          throw new Error(`Unexpected NftType: ${nft.nft_type}`);
      }
    }

    const result: [Nft, Nft, Nft][] = [];

    const numGroups = Math.floor(nfts.length / 3);
    for (let i = 0; i < numGroups; i++) {
      const werewolf = werewolves[i];
      const wolf = wolves[i];
      const raven = ravens[i];
      result.push([werewolf, wolf, raven]);
    }

    return result;
  }

  async function getFullPacksFromQuantity(fixed_packs_number: number) {
    const wolves: Nft[] = [];
    const werewolves: Nft[] = [];
    const ravens: Nft[] = [];

    let packs_number = fixed_packs_number.valueOf();
    const packs_list: Nft[] = [];
    individual_for_packs.value.og.forEach(async (nft) => {
      if (packs_number > 0) {
        // we take 2 from OG, if less than 2 we take 1 if 0 we take 0
        packs_number = packs_number - 1;
        packs_list.push(nft);
      } else {
        wolves.push(nft);
      }
    });
    individual_for_packs.value.wolf.forEach(async (nft) => {
      if (packs_number > 0) {
        // we take 2 from OG, if less than 2 we take 1 if 0 we take 0

        packs_number = packs_number - 1;
        packs_list.push(nft);
      } else {
        wolves.push(nft);
      }
    });
    packs_number = fixed_packs_number;
    individual_for_packs.value.raven.forEach(async (nft) => {
      if (packs_number > 0) {
        // we take 2 from OG, if less than 2 we take 1 if 0 we take 0

        packs_number = packs_number - 1;
        packs_list.push(nft);
      } else {
        ravens.push(nft);
      }
    });
    packs_number = fixed_packs_number;
    individual_for_packs.value.l2.forEach(async (nft) => {
      if (packs_number > 0) {
        // we take 2 from OG, if less than 2 we take 1 if 0 we take 0

        packs_number = packs_number - 1;
        packs_list.push(nft);
      } else {
        werewolves.push(nft);
      }
    });
    individual_for_packs.value.l1.forEach(async (nft) => {
      if (packs_number > 0) {
        // we take 2 from OG, if less than 2 we take 1 if 0 we take 0

        packs_number = packs_number - 1;
        packs_list.push(nft);
      } else {
        werewolves.push(nft);
      }
    });

    individual_for_packs.value.ultra.forEach(async (nft) => {
      werewolves.push(nft);
    });
    full_packs_list.value = packs_list;
    full_packs.value = groupNftsByType(packs_list);
    individual_nfts.value.wolves = wolves;
    individual_nfts.value.werewolves = werewolves;
    individual_nfts.value.ravens = ravens;
  }

  async function getFullPacks() {
    if (account.logged() && account.address !== undefined) {
      const address = new AddressValue(account.address);
      const query = contract.value!.createQuery({
        func: new ContractFunction("getFullPacksQuantity"),
        args: [address],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getFullPacksQuantity")
      );
      if (result.returnCode.isSuccess()) {
        const quantity = result.values[0].valueOf();
        await getFullPacksFromQuantity(quantity);
        return full_packs_list.value;
      }
    }
  }

  async function getMultiplier(type: number) {
    const query = contract.value!.createQuery({
      func: new ContractFunction("getMultiplier"),
      args: [new U64Value(type)],
    });
    const response = await erd.providers.proxy.queryContract(query);
    const resultParser = new ResultsParser();
    const result = resultParser.parseQueryResponse(
      response,
      contract.value!.getEndpoint("getMultiplier")
    );
    if (result.returnCode.isSuccess()) {
      const mult = (result.values[0].valueOf().toNumber() * 10 ** -10).toFixed(
        1
      );
      return mult;
    }
  }

  async function getAcceptedTokenByType(type: number) {
    const query = contract.value!.createQuery({
      func: new ContractFunction("getAcceptedTokenByType"),
      args: [new U64Value(type)],
    });
    const response = await erd.providers.proxy.queryContract(query);
    const resultParser = new ResultsParser();
    const result = resultParser.parseQueryResponse(
      response,
      contract.value!.getEndpoint("getAcceptedTokenByType")
    );
    if (result.returnCode.isSuccess()) {
      return result.values[0].valueOf();
    }
  }

  async function getRewardToken() {
    const query = contract.value!.createQuery({
      func: new ContractFunction("getRewardToken"),
      args: [],
    });
    const response = await erd.providers.proxy.queryContract(query);
    const resultParser = new ResultsParser();
    const result = resultParser.parseQueryResponse(
      response,
      contract.value!.getEndpoint("getRewardToken")
    );
    if (result.returnCode.isSuccess()) {
      tokenIdentifiers.value.token = result.values[0].valueOf();
      return result.values[0].valueOf();
    }
  }

  async function getAcceptedTokens() {
    tokenIdentifiers.value.wolves = (await getAcceptedTokenByType(0)) ?? "";
    tokenIdentifiers.value.werewolves = (await getAcceptedTokenByType(2)) ?? "";
    tokenIdentifiers.value.ravens = (await getAcceptedTokenByType(5)) ?? "";
    tokenIdentifiers.value.bone = (await getAcceptedTokenByType(6)) ?? "";
    getRewardToken();
    return tokenIdentifiers;
  }

  async function getMultipliers() {
    multipliers.value.individual = (await getMultiplier(0)) ?? "";
    multipliers.value.alpha = (await getMultiplier(1)) ?? "";
    multipliers.value.pack = (await getMultiplier(2)) ?? "";
    multipliers.value.bone = (await getMultiplier(3)) ?? "";
    return multipliers;
  }

  async function getRewardRate(type: number) {
    const query = contract.value!.createQuery({
      func: new ContractFunction("getRewardRate"),
      args: [new U64Value(type)],
    });
    const response = await erd.providers.proxy.queryContract(query);
    const resultParser = new ResultsParser();
    const result = resultParser.parseQueryResponse(
      response,
      contract.value!.getEndpoint("getRewardRate")
    );
    if (result.returnCode.isSuccess()) {
      const mult = (result.values[0].valueOf().toNumber() * 10 ** -10).toFixed(
        2
      );
      return mult;
    }
  }

  async function getRewardRates() {
    reward_rates.value.og = (await getRewardRate(0)) ?? "";
    reward_rates.value.wolf = (await getRewardRate(1)) ?? "";
    reward_rates.value.l1 = (await getRewardRate(2)) ?? "";
    reward_rates.value.l2 = (await getRewardRate(3)) ?? "";
    reward_rates.value.ultra = (await getRewardRate(4)) ?? "";
    reward_rates.value.raven = (await getRewardRate(5)) ?? "";

    return reward_rates;
  }

  async function getAlphaPacksNames() {
    if (account.logged() && account.address !== undefined) {
      const address = new AddressValue(account.address);
      const query = contract.value!.createQuery({
        func: new ContractFunction("getAlphaPacksNames"),
        args: [address],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getAlphaPacksNames")
      );
      if (result.returnCode.isSuccess()) {
        alpha_names.value = result.values[0].valueOf();
        return result.values[0].valueOf();
      }
    }
  }

  async function getGlobalStats() {
    await axios
      .get(
        `${mainnet_api}/accounts/${contract_address}/nfts/count?collection=HSRAVEN-d1b3a4`
      )
      .then((res) => {
        global_stats.value.ravens = res.data;
      })
      .catch((err) => console.log(err));
    await axios
      .get(
        `${mainnet_api}/accounts/${contract_address}/nfts/count?collection=HSWEREWOLF-a485c7`
      )
      .then((res) => {
        global_stats.value.werewolves = res.data;
      })
      .catch((err) => console.log(err));

    await axios
      .get(
        `${mainnet_api}/accounts/${contract_address}/nfts/count?collection=HSWOLF-76e8bf`
      )
      .then((res) => {
        global_stats.value.wolves = res.data;
      })
      .catch((err) => console.log(err));

    await axios
      .get(`${mainnet_api}/accounts/${contract_address}/tokens/ALPHA-7e732a`)
      .then((res) => {
        global_stats.value.tokens = (
          (500000000000000000 + 1489001000000000000 - res.data.balance) *
          10 ** -parseInt(res.data.decimals)
        ).toFixed(2);
      })
      .catch((err) => {
        console.log(err);
        global_stats.value.tokens = "0";
      });
  }

  async function getBalance() {
    if (account.logged() && account.address !== undefined) {
      const user_balance_data: any = {
        wolves: [],
        werewolves: [],
        ravens: [],
        ultra: [],
        bones: [],
        tokens: "0",
      };

      // Single request for all NFTs
      await axios
        .get(
          `${erd.providers.api.url}/accounts/${account.address}/nfts?size=10000&collections=${tokenIdentifiers.value.ravens},${tokenIdentifiers.value.wolves},${tokenIdentifiers.value.werewolves},${tokenIdentifiers.value.bone},`
        )
        .then((res) => {
          res.data.forEach((nft: any) => {
            const new_nft: Nft = {
              name: nft.nonce,
              nonce: nft.nonce,
              ticker: nft.collection,
              nft_type: { name: "" },
              media: nft.url,
              last_claim: 0,
            };

            // Categorize NFT based on its collection
            if (nft.collection === tokenIdentifiers.value.ravens) {
              new_nft.nft_type.name = "Raven";
              user_balance_data.ravens.push(new_nft);
            } else if (nft.collection === tokenIdentifiers.value.wolves) {
              new_nft.nft_type.name = nft.nonce <= 666 ? "Og" : "Wolf";
              user_balance_data.wolves.push(new_nft);
            } else if (nft.collection === tokenIdentifiers.value.werewolves) {
              const length = nft.metadata.attributes.length;
              const nft_type = "l" + nft.metadata.attributes[length - 1].value;
              new_nft.name = nft.metadata.name.split("#")[1];
              if (nft_type.includes("l3")) {
                new_nft.nft_type.name = "Ultra";
                new_nft.name = nft.metadata.name;
                user_balance_data.ultra.push(new_nft);
              } else {
                new_nft.nft_type.name = nft_type;
                user_balance_data.werewolves.push(new_nft);
              }
            } else if (nft.collection === tokenIdentifiers.value.bone) {
              new_nft.name = "Golden Bone";
              new_nft.nft_type.name = "X" + nft.balance;
              user_balance_data.bones.push(new_nft);
            }
          });
        })
        .catch((err) => console.log(err));

      // Request for token balance remains separate
      await axios
        .get(
          `${erd.providers.api.url}/accounts/${account.address}/tokens/${tokenIdentifiers.value.token}`
        )
        .then((res) => {
          user_balance_data.tokens = (
            res.data.balance *
            10 ** -parseInt(res.data.decimals)
          ).toFixed(2);
        })
        .catch((err) => {
          console.log(err);
          user_balance_data.tokens = "0";
        });

      // Sorting function
      const sortByName = (a: any, b: any) =>
        parseInt(a.name) - parseInt(b.name);

      // Sort each list by name
      user_balance_data.wolves.sort(sortByName);
      user_balance_data.werewolves.sort(sortByName);
      user_balance_data.ravens.sort(sortByName);
      user_balance_data.ultra.sort(sortByName);

      // Update user balance
      user_balance.value = user_balance_data;
    }
  }

  async function getAlphaPackByName(name: number) {
    if (account.logged() && account.address !== undefined) {
      const address = new AddressValue(account.address);
      const query = contract.value!.createQuery({
        func: new ContractFunction("getAlphaPackByNameWithTimestamp"),
        args: [address, new U64Value(name)],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getAlphaPackByNameWithTimestamp")
      );
      if (result.returnCode.isSuccess()) {
        const nfts = result.values[0].valueOf();
        nfts.forEach(async (nft: any) => {
          nft.name = nft.name.valueOf();
          nft.alpha = true;
        });
        alpha_packs.value.push(...[nfts]);
        return nfts;
      }
    }
  }

  async function getAlphaPacks() {
    if (account.logged() && account.address !== undefined) {
      alpha_names.value = await getAlphaPacksNames();
      alpha_names.value.forEach(async (name: any) => {
        await getAlphaPackByName(name);
      });
    }
  }

  async function getNftLastClaim(ticker: any, nonce: any) {
    if (account.logged() && account.address !== undefined) {
      const address = new AddressValue(account.address);
      const tokenIdentifier = new TokenIdentifierValue(ticker);
      const nonce_u64 = new U64Value(nonce);
      const query = contract.value!.createQuery({
        func: new ContractFunction("getNftLastClaim"),
        args: [address, tokenIdentifier, nonce_u64],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getNftLastClaim")
      );
      if (result.returnCode.isSuccess()) {
        return result.values[0].valueOf();
      }
    }
  }

  async function getUnbondingPeriod() {
    if (account.logged() && account.address !== undefined) {
      const query = contract.value!.createQuery({
        func: new ContractFunction("getUnbondingPeriod"),
        args: [],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getUnbondingPeriod")
      );
      if (result.returnCode.isSuccess()) {
        unbonding_period.value = result.values[0].valueOf().c[0];
        return result.values[0].valueOf();
      }
    }
  }

  async function getRewardPeriod() {
    if (account.logged() && account.address !== undefined) {
      const query = contract.value!.createQuery({
        func: new ContractFunction("getRewardPeriod"),
        args: [],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getRewardPeriod")
      );
      if (result.returnCode.isSuccess()) {
        reward_period.value = result.values[0].valueOf().c[0];
        return result.values[0].valueOf();
      }
    }
  }

  async function getRewards() {
    if (account.logged() && account.address !== undefined) {
      const address = new AddressValue(account.address);
      const query = contract.value!.createQuery({
        func: new ContractFunction("getRewards"),
        args: [address],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getRewards")
      );
      if (result.returnCode.isSuccess()) {
        rewards.value = (
          result.values[0].valueOf().toNumber() *
          10 ** -10
        ).toFixed(2);
        return result.values[0].valueOf();
      }
    }
  }

  const medias: any = [];
  medias[1] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/21.png",
    name: "Ultra Beni Werewolf",
  };
  medias[15] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/10.png",
    name: "Ultra Floral Werewolf",
  };
  medias[569] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/9.png",
    name: "Ultra Flame Enigma Werewolf",
  };
  medias[811] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/13.png",
    name: "Ultra Liquid Werewolf",
  };
  medias[1068] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/19.png",
    name: "Ultra Zombie Portrait Werewolf",
  };
  medias[1082] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/17.png",
    name: "Ultra Raven-Mecha Werewolf",
  };
  medias[1153] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/20.png",
    name: "Ultra Zombie Wizard Werewolf",
  };
  medias[1323] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/4.png",
    name: "Ultra Celestial Cerberus Werewolf",
  };
  medias[1738] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/14.png",
    name: "Ultra Maiar Werewolf",
  };
  medias[1810] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/15.png",
    name: "Ultra Matrix Werewolf",
  };
  medias[2094] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/11.png",
    name: "Ultra Hypebeast Werewolf",
  };
  medias[2216] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/18.png",
    name: "Ultra Tribal Werewolf",
  };
  medias[2224] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/12.png",
    name: "Ultra Kintsugi Skeleton Werewolf",
  };
  medias[2567] = {
    media:
      "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/16.png",
    name: "Ultra Plasma Werewolf",
  };

  async function ultraMedia(ticker: any, nonce: string) {
    nonce = intToHex(nonce);
    try {
      const res = await axios.get(
        `${erd.providers.api.url}/nfts/${ticker}-${nonce}`
      );
      return [res.data.media[0].url, res.data.name];
    } catch (err) {
      console.log(err);
      return ""; // or some default value if you want to handle errors differently
    }
  }

  const sortByName = (a: any, b: any) => parseInt(a.name) - parseInt(b.name);

  async function getIndividual() {
    if (account.logged() && account.address !== undefined) {
      const wolves: Nft[] = [];
      const werewolves: Nft[] = [];
      const ravens: Nft[] = [];
      const og: Nft[] = [];
      const wolf: Nft[] = [];
      const l1: Nft[] = [];
      const l2: Nft[] = [];

      reward_period.value = await getRewardPeriod();
      og.push(...(await getIndividualByType(0)));
      wolf.push(...(await getIndividualByType(1)));
      wolves.push(...og);
      wolves.push(...wolf);

      l1.push(...(await getIndividualByType(2)));
      l2.push(...(await getIndividualByType(3)));
      werewolves.push(...l1);
      werewolves.push(...l2);
      const ultras = await getIndividualByType(4);
      ultras.forEach(async (nft: any) => {
        if (medias[parseInt(nft.nonce)]) {
          nft.media = medias[parseInt(nft.nonce)].media;
          nft.name = medias[parseInt(nft.nonce)].name;
        } else {
          const media = await ultraMedia(nft.ticker, nft.nonce);
          nft.media = media[0];
          nft.name = media[1];
        }
      });
      ravens.push(...(await getIndividualByType(5)));
      individual_for_packs.value.og = og;
      individual_for_packs.value.wolf = wolf;
      individual_for_packs.value.raven = ravens;
      individual_for_packs.value.l1 = l1;
      individual_for_packs.value.l2 = l2;
      individual_for_packs.value.ultra = ultras;

      await getFullPacks();
      return individual_nfts;
    }
  }

  async function getIndividualByType(type: number) {
    /*local OG=0x00
    local WOLF=0x01
    local L1=0x02
    local L2=0x03
    local ULTRA=0x04
    local RAVEN=0x05
    local BONE=0x06*/
    if (account.logged() && account.address !== undefined) {
      const address = new AddressValue(account.address);
      const query = contract.value!.createQuery({
        func: new ContractFunction("getIndividualByTypeWithTimestamp"),
        args: [address, new U64Value(type)],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getIndividualByTypeWithTimestamp")
      );
      if (result.returnCode.isSuccess()) {
        const nfts = result.values[0].valueOf();
        nfts.forEach((nft: any) => {
          nft.last_claim = parseInt(nft.last_claim.valueOf());
        });
        return nfts;
      }
    }
  }

  async function getTokenData(ticker: any, nonce: any) {
    const query = contract.value!.createQuery({
      func: new ContractFunction("getTokenData"),
      args: [new TokenIdentifierValue(ticker), new U64Value(nonce)],
    });
    const response = await erd.providers.proxy.queryContract(query);
    const resultParser = new ResultsParser();
    const result = resultParser.parseQueryResponse(
      response,
      contract.value!.getEndpoint("getTokenData")
    );
    if (result.returnCode.isSuccess()) {
      return result.values[0].valueOf();
    }
  }
  async function getUnbonding() {
    if (account.logged() && account.address !== undefined) {
      const unbonding_nfts: any[] = [];
      const address = new AddressValue(account.address);
      const query = contract.value!.createQuery({
        func: new ContractFunction("getUnstaking"),
        args: [address],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getUnstaking")
      );
      if (result.returnCode.isSuccess()) {
        const nfts = result.values[0].valueOf();
        nfts.forEach(async (nft: any) => {
          const unbonding_nft: UnbondingNft = nft;
          unbonding_nft.ticker = nft.ticker;
          unbonding_nft.nonce = nft.nonce;
          unbonding_nft.unstake_timestamp = nft.unstake_timestamp.valueOf();
          if (nft.ticker == tokenIdentifiers.value.werewolves) {
            const nft_data = await getTokenData(nft.ticker, nft.nonce);
            unbonding_nft.name = nft_data.name.valueOf();
            unbonding_nft.nft_type = nft_data.nft_type;
            if (unbonding_nft.nft_type.name == "Ultra") {
              if (medias[parseInt(nft.nonce)]) {
                unbonding_nft.media = medias[parseInt(nft.nonce)].media;
                unbonding_nft.name = medias[parseInt(nft.nonce)].name;
              } else {
                const media = await ultraMedia(nft.ticker, nft.nonce);
                unbonding_nft.media = media[0];
                unbonding_nft.name = media[1];
              }
            }
          } else {
            unbonding_nft.name = nft.nonce;
            if (nft.ticker == tokenIdentifiers.value.ravens) {
              unbonding_nft.nft_type = { name: "Raven" };
            } else if (nft.ticker == tokenIdentifiers.value.wolves) {
              if (nft.nonce <= 666) {
                unbonding_nft.nft_type = { name: "Og" };
              } else {
                unbonding_nft.nft_type = { name: "Wolf" };
              }
            } else if (nft.ticker == tokenIdentifiers.value.bone) {
              unbonding_nft.nft_type = { name: "Bone" };
              unbonding_nft.name = "Golden Bone";
            }
          }
          unbonding_nfts.push(...[unbonding_nft]);
          const current_timestamp = Math.floor(Date.now() / 1000);
          const periods_elapsed =
            (current_timestamp - nft.unstake_timestamp) /
            unbonding_period.value;
          if (Math.floor(periods_elapsed) > 0) {
            ready_to_claim.value.push(...[unbonding_nft]);
            console.log("NFT finished unbonding:");
            console.log(unbonding_nft);
          }
        });
        unbonding.value = unbonding_nfts;
        return nfts;
      }
    }
  }

  async function getGoldenBones() {
    if (account.logged() && account.address !== undefined) {
      const address = new AddressValue(account.address);
      const query = contract.value!.createQuery({
        func: new ContractFunction("getGoldenBones"),
        args: [address],
      });
      const response = await erd.providers.proxy.queryContract(query);
      const resultParser = new ResultsParser();
      const result = resultParser.parseQueryResponse(
        response,
        contract.value!.getEndpoint("getGoldenBones")
      );
      if (result.returnCode.isSuccess()) {
        golden_bones.value = result.values[0].valueOf().c[0];
        if (golden_bones.value > 0) {
          const nft: Nft = {
            name: "Golden Bone",
            nonce: "1",
            ticker: tokenIdentifiers.value.bone,
            nft_type: { name: "Bone" },
            media: "url(@/assets/goldebone.gif)",
            last_claim: 0,
          };
          if (individual_nfts.value.bones.length < 1) {
            individual_nfts.value.bones.push(...[nft]);
          }
        }
        return result.values[0].valueOf();
      }
    }
  }

  async function sendTransaction() {
    if (account.logged() && account.address !== undefined) {
      sender_account.value = await fetchAccount();
      transactionResult.value = null;
      transactionState.value = null;
      transactionUrl.value = null;
      const networkConfig = await erd.proxy.getNetworkConfig();
      const transaction = new Transaction({
        data: new TransactionPayload(
          "MultiESDTNFTTransfer@000000000000000005002d2262dbc663006c2d8db28d033fed036f95e2dfe2e0@06@4853524156454e2d313938363366@01@01@4853524156454e2d313938363366@01@01@4853524156454e2d313938363366@01@01@4853524156454e2d313938363366@0107@01@4853524156454e2d313938363366@0107@01@4853524156454e2d313938363366@0107@01@73757065725374616b65"
        ),
        gasLimit: 60000000,
        receiver: new Address(account.address.bech32()),
        value: TokenPayment.egldFromAmount(0),
        chainID: networkConfig.ChainID,
        sender: new Address(account.address.bech32()),
      });
      transaction.setNonce(sender_account.value!.getNonceThenIncrement());
      transactionState.value = "Waiting for transaction to be signed";
      erd.providers
        .signAndSend(transaction)
        .then((result: Transaction) => {
          transactionState.value = "Waiting for transaction to be validated";
          transactionUrl.value = erd.explorerTransactionUrl(result);
          return erd.providers.transactionResult(result);
        })
        .catch((error: Error) => {
          console.error(error);
          transactionResult.value = error;
        });
    }
  }
  async function getTxStatus(hash: string) {
    const data = await axios
      .get(`${erd.providers.api.url}/transaction/${hash}`)
      .then((res) => {
        return res.data.status;
      })
      .catch((err) => {
        return "fail";
      });
    return data;
  }

  function initTxToaster() {
    toast_info.value = {
      title: "Transaction pending!",
      message: "Processing your transaction.",
      status: null,
      link: transactionUrl.value,
    };
    toast_id.value = toast(Msg, {
      autoClose: true,
      closeOnClick: false,
      closeButton: true,
      type: "loading",
      isLoading: true,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  //////////////////////
  /* CHECK FOR ALPHA  */
  /* TO OPTIMISE TX   */
  //////////////////////

  const order: Record<any, number> = {
    Og: 0,
    Wolf: 1,
    Raven: 2,
    l2: 3,
    l1: 4,
  };

  function compareNFTs(a: Nft, b: Nft) {
    const orderA = order[a.nft_type.name];
    const orderB = order[b.nft_type.name];

    return orderA - orderB;
  }

  async function checkForAlphas(nfts: any) {
    const alpha: any[][] = []; // Declare alpha as a list of lists of Nft objects

    nfts.forEach((nft: any) => {
      const name = nft.name;
      let nftList = alpha.find((list) => list[0]?.name === name); // Find the first list that contains an Nft with the same name
      if (!nftList) {
        nftList = []; // Initialize a new list if no existing list is found
        alpha.push(nftList);
      }
      nftList.push(nft); // Add nft to the list with the same name
    });

    // Sort the inner lists according to the specified order
    for (const nftList of alpha) {
      nftList.sort(compareNFTs);
    }

    // Sort the outer list according to the size of the inner lists
    alpha.sort((a, b) => b.length - a.length);
    const final_list: any[] = [];
    alpha.forEach((pack) => {
      pack.forEach((nft) => {
        final_list.push(nft);
      });
    });
    return final_list;
    // Use alpha object for further processing
  }

  /////////////////
  /* STAKING TX  */
  /////////////////
  function shuffleArray<T>(array: T[]): T[] {
    // Loop over array from the end to the beginning
    for (let i = array.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      const j = Math.floor(Math.random() * (i + 1));
      // Swap elements at i and j
      [array[i], array[j]] = [array[j], array[i]];
    }
    // Return shuffled array
    return array;
  }

  async function stake(items: any[]) {
    if (account.logged() && account.address !== undefined) {
      let alreadyClicked = false;
      sender_account.value = await fetchAccount();
      // Put info on stake modal that only 50 at a time can be staked.
      items = shuffleArray(items);
      if (items.length >= 50) {
        items = items.slice(0, 50);
      }

      const tokens: Nft[] = await checkForAlphas(items);
      //const gasLimit = 3.84217 * 10 ** 6 + 3.5707 * 10 ** 6 * tokens.length;
      let gasLimit = Math.ceil(3399269 + 3640683 * tokens.length * 1.4);
      if (gasLimit <= 60_000_000) {
        gasLimit += 60_000_000;
      }
      console.log(tokens);
      console.log(gasLimit);
      //const address = new AddressValue(new Address("erd1jzuwmhxvqhmax8we9jgdfcv0w2hywxmk57kswfr9vu3cm9gyfstqcj52z2"));
      const payments: any[] = [];
      tokens.forEach((nft) => {
        if (nft.ticker === tokenIdentifiers.value.bone) {
          payments.push(
            TokenPayment.semiFungible(nft.ticker, parseInt(nft.nonce), 1)
          );
        } else {
          const ticker = nft.ticker;
          payments.push(TokenPayment.nonFungible(ticker, parseInt(nft.nonce)));
        }
      });
      const networkConfig = await erd.proxy.getNetworkConfig();
      console.log("PROVIDERS");
      console.log(networkConfig);
      console.log(erd.proxy.config.timeout);
      const interaction = contract
        .value!.methods.superStake([])
        .withValue(0)
        .withGasLimit(gasLimit)
        .withChainID(networkConfig.ChainID)
        .withMultiESDTNFTTransfer(payments, sender_account.value!.address);
      const tx_info = interaction.buildTransaction();
      const tx = new Transaction({
        data: tx_info.getData(),
        gasLimit: tx_info.getGasLimit(),
        receiver: sender_account.value!.address,
        value: TokenPayment.egldFromAmount(0),
        chainID: networkConfig.ChainID,
        sender: sender_account.value!.address,
      });
      console.log(sender_account.value!.address);
      console.log("tx:");
      console.log(tx);
      console.log(tx.getData().toString());
      tx.setNonce(sender_account.value!.getNonceThenIncrement());
      await erd.providers
        .signAndSend(tx)
        .then((result: Transaction) => {
          transactionUrl.value = erd.explorerTransactionUrl(result);
          initTxToaster();
          console.log(transactionUrl.value);
          const res = erd.providers.transactionResult(result);
          alreadyClicked = true;
        })
        .catch(async (error: Error) => {
          console.error(error);
          console.log(error);
          if (error.message.includes("No matching key")) {
            toast_info.value = {
              title: "Wallet connection lost.",
              message: "Please reconnect.",
              status: null,
              link: null,
            };
            toast_id.value = toast(Msg, {
              autoClose: 5000,
              closeOnClick: true,
              closeButton: true,
              type: "warning",
              isLoading: false,
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            await sleep(5000);
            wipe();
            erd.logout();
          }
        });
      return alreadyClicked;
    }
  }

  function getTypeU64(nft_type: any) {
    if (nft_type == "Og") {
      return 0;
    } else if (nft_type == "Wolf") {
      return 1;
    } else if (nft_type == "L1") {
      return 2;
    } else if (nft_type == "L2") {
      return 3;
    } else if (nft_type == "Ultra") {
      return 4;
    } else if (nft_type == "Raven") {
      return 5;
    }
    return 6;
  }
  // Unstake tx
  async function unstake(items: any[]) {
    if (account.logged() && account.address !== undefined) {
      sender_account.value = await fetchAccount();
      if (items.length >= 50) {
        items = items.slice(0, 50);
      }
      //const gasLimit = 3.84217 * 10 ** 6 + 3.5707 * 10 ** 6 * tokens.length;
      let gasLimit = Math.ceil(3399269 + 3640683 * items.length * 1.4);
      if (gasLimit <= 60_000_000) {
        gasLimit += 60_000_000;
      }
      const args: any[] = [];
      items.forEach((nft) => {
        const type = getTypeU64(nft.nft_type.name);
        if (nft.alpha === true) {
          args.push(
            new U64Value(1),
            new TokenIdentifierValue(nft.ticker),
            new U64Value(nft.nonce),
            new U64Value(type)
          );
        } else {
          args.push(
            new U64Value(0),
            new TokenIdentifierValue(nft.ticker),
            new U64Value(nft.nonce),
            new U64Value(type)
          );
        }
      });
      const networkConfig = await erd.proxy.getNetworkConfig();
      const interaction = contract
        .value!.methodsExplicit.unstake(args)
        .withValue(0)
        .withGasLimit(gasLimit)
        .withChainID(networkConfig.ChainID);
      const tx_info = interaction.buildTransaction();
      const tx = new Transaction({
        data: tx_info.getData(),
        gasLimit: tx_info.getGasLimit(),
        receiver: new Address(contract_address),
        value: TokenPayment.egldFromAmount(0),
        chainID: networkConfig.ChainID,
        sender: sender_account.value!.address,
      });
      console.log(sender_account.value!.address);
      console.log("tx:");
      console.log(tx);
      console.log(tx.getData().toString());
      tx.setNonce(sender_account.value!.getNonceThenIncrement());
      await erd.providers
        .signAndSend(tx)
        .then((result: Transaction) => {
          transactionUrl.value = erd.explorerTransactionUrl(result);
          initTxToaster();
          console.log(transactionUrl.value);
          const res = erd.providers.transactionResult(result);
          return res;
        })
        .catch((error: Error) => {
          console.error(error);
          transactionResult.value = error;
        });
      return { sessionId: null, error: "tx invalid" };
    }
  }

  // Unstake tx
  async function claimRewards() {
    if (account.logged() && account.address !== undefined) {
      sender_account.value = await fetchAccount();
      const networkConfig = await erd.proxy.getNetworkConfig();
      const staked =
        individual_nfts.value.wolves.length +
        individual_nfts.value.werewolves.length +
        individual_nfts.value.ravens.length +
        individual_nfts.value.ultra.length +
        individual_nfts.value.bones.length +
        full_packs_list.value.length +
        alpha_packs.value.length * 3;
      let gasLimit = 60_000_000;
      if (staked >= 20) {
        if (staked > 20) {
          gasLimit = 100_000_000;
        }
        if (staked > 25) {
          gasLimit = 120_000_000;
        }
        if (staked > 40) {
          gasLimit = 160_000_000;
        }
        if (staked > 50) {
          gasLimit = 180_000_000;
        }
        if (staked > 65) {
          gasLimit = 200_000_000;
        }
        if (staked > 100) {
          gasLimit = 250_000_000;
        }
        if (staked > 120) {
          gasLimit = 350_000_000;
        }
        if (staked > 150) {
          gasLimit = 450_000_000;
        }
      }
      const interaction = contract
        .value!.methods.claimRewards([])
        .withValue(0)
        .withGasLimit(gasLimit)
        .withChainID(networkConfig.ChainID);
      const tx_info = interaction.buildTransaction();
      const tx = new Transaction({
        data: tx_info.getData(),
        gasLimit: tx_info.getGasLimit(),
        receiver: new Address(contract_address),
        value: TokenPayment.egldFromAmount(0),
        chainID: networkConfig.ChainID,
        sender: sender_account.value!.address,
      });
      console.log(sender_account.value!.address);
      console.log("tx:");
      console.log(tx);
      console.log(tx.getData().toString());
      tx.setNonce(sender_account.value!.getNonceThenIncrement());
      await erd.providers
        .signAndSend(tx)
        .then((result: Transaction) => {
          transactionUrl.value = erd.explorerTransactionUrl(result);
          initTxToaster();
          console.log(transactionUrl.value);
          const res = erd.providers.transactionResult(result);
          return res;
        })
        .catch((error: Error) => {
          console.error(error);
          transactionResult.value = error;
        });
      return { sessionId: null, error: "tx invalid" };
    }
  }

  // Unstake tx
  async function claimUnbonded() {
    if (account.logged() && account.address !== undefined) {
      sender_account.value = await fetchAccount();
      const networkConfig = await erd.proxy.getNetworkConfig();
      let gas = Math.ceil(3399269 + 3640683 * unbonding.value.length);
      if (gas < 60000000) {
        gas = 60000000;
      }
      if (gas > 600000000) {
        gas = 600000000;
      }
      const interaction = contract
        .value!.methods.claimUnbonded([])
        .withValue(0)
        .withGasLimit(gas)
        .withChainID(networkConfig.ChainID);
      const tx_info = interaction.buildTransaction();
      const tx = new Transaction({
        data: tx_info.getData(),
        gasLimit: tx_info.getGasLimit(),
        receiver: new Address(contract_address),
        value: TokenPayment.egldFromAmount(0),
        chainID: networkConfig.ChainID,
        sender: sender_account.value!.address,
      });
      console.log(sender_account.value!.address);
      console.log("tx:");
      console.log(tx);
      console.log(tx.getData().toString());
      tx.setNonce(sender_account.value!.getNonceThenIncrement());
      await erd.providers
        .signAndSend(tx)
        .then((result: Transaction) => {
          transactionUrl.value = erd.explorerTransactionUrl(result);
          initTxToaster();
          console.log(transactionUrl.value);
          const res = erd.providers.transactionResult(result);
          return res;
        })
        .catch((error: Error) => {
          console.error(error);
          transactionResult.value = error;
        });
      return { sessionId: null, error: "tx invalid" };
    }
  }

  return {
    init,
    wipe,
    reset,
    contract_address,
    // User info
    sender_account,
    user_balance,
    // Staked nfts
    full_packs,
    alpha_names,
    alpha_packs,
    individual_nfts,
    golden_bones,
    // Staking info
    global_stats,
    actual_period,
    reward_period,
    rewards,
    earning,
    unbonding_period,
    unbonding,
    unstakeArray,
    ready_to_claim,
    multipliers,
    reward_rates,
    // Transaction info
    transactionState,
    transactionResult,
    transactionUrl,
    active_toast,
    toast_id,
    toast_info,
    earned,
    setContract,
    getMultipliers,
    getRewardRates,
    getFullPacks,
    getAlphaPackByName,
    getAlphaPacksNames,
    getIndividual,
    getIndividualByType,
    getNftLastClaim,
    getRewardPeriod,
    getRewards,
    getUnbonding,
    getGoldenBones,
    getEarnings,
    sendTransaction,
    stake,
    unstake,
    claimUnbonded,
    claimRewards,
    getGlobalStats,
  };
});

export default useStoreStaking;
