<template>
  <section id="nft">
    <div class="nft-content-item-unbond nft-base-wrapper">
      <div class="nft-image-wrapper nft-base-image-unbond">
        <div class="image-container">
          <img
            v-if="
              nft.nft_type.name == 'Wolf' ||
              nft.nft_type.name == 'Raven' ||
              nft.nft_type.name == 'Og'
            "
            :src="media[nft.nft_type.name] + nft.nonce + '.png'"
            alt="nft"
            class="nft-image"
          />
          <img
            v-else-if="nft.nft_type.name == 'L1' || nft.nft_type.name == 'L2'"
            :src="media[nft.nft_type.name] + nft.name + '.png'"
            alt="nft"
            class="nft-image"
          />
          <img
            v-else-if="nft.nft_type.name === 'Bone'"
            :src="require('@/assets/goldebone.gif')"
            :style="'padding: 20px'"
            alt="nft"
            class="nft-image"
          />
          <img v-else :src="nft.media" alt="nft" class="nft-image" />
        </div>
        <div class="box-wrapper">
          <div class="box">
            <p>{{ nft.nft_type.name.toUpperCase() }}</p>
          </div>
        </div>
      </div>
      <p class="nft-name">
        {{
          nft.nft_type.name === "Ultra"
            ? nft.name
            : nft.nft_type.name === "Bone"
            ? nft.name
            : nft.ticker.split("-")[0] + "#" + nft.name
        }}
      </p>

      <div class="timer">
        <span>{{ getRemaining() }}</span>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
@import "./UnbondingCard.scss";
</style>

<script lang="js">
export default {
  props: {
    nft: {
      type: Object,
      default() {
        return {};
      },
    },
    unbondingPeriod: {
      type: Number,
      default: 604800,
    },
    readyToClaim: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isSelected: false, // switch for button
      showBoxPlus: false, // switch for box-plus
      media: {
        Og: "https://media.elrond.com/nfts/asset/QmemHXkBYZwUFZ4fMEgaTupzPS4PALnTmB7HEHSZm5Kt5J/",
        Wolf: "https://media.elrond.com/nfts/asset/QmemHXkBYZwUFZ4fMEgaTupzPS4PALnTmB7HEHSZm5Kt5J/",
        L1: "https://media.elrond.com/nfts/asset/QmXGPKjSn2huDEanmxrWq8WqiWvBgmQjTHqDsu1Qj6smuw/",
        L2: "https://media.elrond.com/nfts/asset/QmRGAUzxJ1aR55mWkuyFKCBLUEqGkwgc9zN57rcvwqxR3w/",
        Ultra:
          "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/",
        Raven:
          "https://media.elrond.com/nfts/asset/QmYjvaYuDjGBmqBgZAbK9dKzXxURpe1mXbLpyieJAkr71n/",
        Bone: "https://media.elrond.com/nfts/asset/QmWfXssATKGEZr4QuFyXXL3VApeHWowWuHrSQ8CmNCrLUS/goldebone.gif",
      },
      remaining: "",
      done: false,
      interval: 1000,
      intervalId: null, // store the interval id for clearing later
    }
  },
  mounted() {
    if (this.remaining.includes("DAYS") || this.remaining.includes("h")) {
      this.interval = 1000;
    }
    this.intervalId = setInterval(this.getRemaining, this.interval); // call getRemaining with the updated interval
  },
  beforeUnmount() {
    clearInterval(this.intervalId); // clear the interval when the component is destroyed
  },
  methods: {
    getUltraMedia(name) {
      if (name === "Ultra Beni Werewolf") {
        return "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/21.png";
      }
    },

    secondsToDhms(seconds) {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600*24));
      var h = Math.floor(seconds % (3600*24) / 3600);
      var m = Math.floor(seconds % 3600 / 60);
      var s = Math.floor(seconds % 60);
      if(d>0){
          if(h==0){
              return d+' DAYS'
          }
          return d+ 'D:'+h+'h'
      }
      if(h>0){
          if(m==0){
              return h+'h'
          }
          return h+'h:'+m+'m'
      }
      if(m>0){
          if(s==0){
              return m+'m'
          }
          return m+'m:'+s+'s'
      }
      return s+'s';
    },
    getRemaining() {
      var duration = this.unbondingPeriod;
      let nft_date_seconds = this.nft.unstake_timestamp;
      let now_seconds = (new Date().getTime() / 1000).toFixed(0)
      let passed = now_seconds - nft_date_seconds;
      if (passed >=  duration){
          this.remaining = "CLAIMABLE"
          if (!this.done){
            let contains = false;
            this.readyToClaim?.forEach((ready) => {
              if (ready.ticker == this.nft.ticker && ready.nonce == this.nft.nonce) {
                contains = true;
              }
            });
            if (!contains) {
              this.$emit("nft-claimable", this.nft);
            }
            this.done = true;
          }
          return "CLAIMABLE"
      }
      let info = this.secondsToDhms(duration - passed);
      this.remaining = info;
      return this.remaining;
    }
  },
};
</script>
