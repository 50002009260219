<template>
  <!-- STATS -->
  <div :style="'display: contents;'">
    <div class="stats-box">
      <div class="bigSectionBox" style="margin-right: 8%">
        <p class="titleElement">STAKING STATS</p>
        <div class="stats" v-if="$erdAccount.logged()">
          <p class="subtitleElement">STAKED NFTS:</p>
          <p class="statElement">
            Individual:
            {{
              individual.wolves?.length +
              individual.werewolves?.length +
              individual.ravens?.length
            }}
          </p>
          <p class="statElement">Golden Bone: {{ individual.bones?.length }}</p>
          <p class="statElement">Alpha Packs: {{ alphaPacks?.length }}</p>
          <p class="statElement">Full Packs: {{ fullPacks?.length }}</p>
          <p class="subtitleElement">UNSTAKED NFTS:</p>
          <p class="statElement">
            Unbonding NFTs:
            {{ unbonding.length }}
          </p>
          <p class="statElement">Claimable NFTs: {{ readyToClaim?.length }}</p>
        </div>
        <p v-else class="normalText">You have to connect to see stats</p>
        <br />
        <br />
        <button
          class="buttonCommon"
          :disabled="readyToClaim?.length < 1"
          :class="{
            disabledButton: readyToClaim?.length < 1 || !$erdAccount.logged(),
          }"
          @click="claimNfts"
        >
          <span>CLAIM NFTs</span>
        </button>
      </div>
      <div class="bigSectionBox">
        <p class="titleElement">$ALPHA STATS</p>
        <div
          class="stats"
          v-if="$erdAccount.logged()"
          :style="'margin-top: 30px; margin-bottom: 30px'"
        >
          <p class="subtitleElement">BALANCE:</p>
          <p class="statElement">
            {{ parseFloat(balance).toLocaleString("en-US") }} $ALPHA
          </p>
          <p class="subtitleElement">EARNING:</p>
          <p class="statElement">
            ~ {{ parseFloat(earning).toLocaleString("en-US") }} $ALPHA / DAY
          </p>
          <p class="subtitleElement">CLAIMABLE:</p>
          <p class="statElement">
            {{ parseFloat(rewards).toLocaleString("en-US") }} $ALPHA
          </p>
        </div>
        <p v-else class="normalText">You have to connect to see stats</p>
        <br />
        <br />
        <br />
        <button
          class="buttonCommon"
          :class="{ disabledButton: !$erdAccount.logged() || rewards <= 0 }"
          @click="claimRewards"
        >
          <span>CLAIM REWARDS</span>
        </button>
      </div>
    </div>
    <!-- STAKE GLOBAL STATS -->
    <div class="bigSectionBox">
      <p class="titleElement">GLOBAL STATS</p>
      <div class="stats-buttons">
        <div class="statsBlock">
          <p class="subtitleElement">$ALPHA DISTRIBUTED:</p>
          <p class="globalStatsInfoElement">
            {{ this.globalStats.tokens }}
          </p>
        </div>
        <div class="statsBlock">
          <p class="subtitleElement">WOLVES STAKED:</p>
          <p class="globalStatsInfoElement">
            {{ this.globalStats.wolves }}
          </p>
        </div>
        <div class="statsBlock">
          <p class="subtitleElement">WEREWOLVES STAKED:</p>
          <p class="globalStatsInfoElement">
            {{ this.globalStats.werewolves }}
          </p>
        </div>
        <div class="statsBlock">
          <p class="subtitleElement">RAVENS STAKED:</p>
          <p class="globalStatsInfoElement">
            {{ this.globalStats.ravens }}
          </p>
        </div>
      </div>
    </div>
    <!-- STAKE AND UNSTAKE -->
    <div class="bigSectionBox">
      <p class="titleElement">STAKE & UNSTAKE</p>
      <p class="normalText">Stake your NFTs now to earn $ALPHA tokens</p>
      <div class="stats-buttons">
        <button
          class="buttonCommon"
          :class="{
            disabledButton:
              !$erdAccount.logged() ||
              this.userBalance.wolves.length +
                this.userBalance.werewolves.length +
                this.userBalance.ravens.length +
                this.userBalance.ultra.length +
                this.userBalance.bones.length <
                1,
          }"
          @click="showModal"
        >
          <span>STAKE NFTs</span>
        </button>
        <button
          v-if="
            this.userBalance.wolves.length +
              this.userBalance.werewolves.length +
              this.userBalance.ravens.length +
              this.userBalance.ultra.length +
              this.userBalance.bones.length >=
            50
          "
          class="buttonCommon"
          :class="{
            disabledButton:
              !$erdAccount.logged() ||
              this.userBalance.wolves.length +
                this.userBalance.werewolves.length +
                this.userBalance.ravens.length +
                this.userBalance.ultra.length +
                this.userBalance.bones.length <
                1,
          }"
          @click="stakeAll"
        >
          <span>STAKE 50</span>
        </button>
        <button
          v-else
          class="buttonCommon"
          :class="{
            disabledButton:
              !$erdAccount.logged() ||
              this.userBalance.wolves.length +
                this.userBalance.werewolves.length +
                this.userBalance.ravens.length +
                this.userBalance.ultra.length +
                this.userBalance.bones.length <
                1,
          }"
          @click="stakeAll"
        >
          <span>STAKE ALL</span>
        </button>
        <button
          class="buttonCommon"
          v-if="
            this.individual.wolves.length +
              this.individual.werewolves.length +
              this.individual.ravens.length +
              this.individual.bones.length +
              this.alphaPacks.length * 3 +
              this.fullPacks.length * 3 >=
            50
          "
          :class="{
            disabledButton:
              !$erdAccount.logged() ||
              individual.wolves?.length +
                individual.werewolves?.length +
                individual.ravens?.length +
                individual.bones?.length +
                fullPacks?.length +
                alphaPacks?.length <
                1,
          }"
          @click="unstakeAll"
        >
          <span>UNSTAKE 50</span>
        </button>
        <button
          class="buttonCommon"
          v-else
          :class="{
            disabledButton:
              !$erdAccount.logged() ||
              individual.wolves?.length +
                individual.werewolves?.length +
                individual.ravens?.length +
                individual.bones?.length +
                fullPacks?.length +
                alphaPacks?.length <
                1,
          }"
          @click="unstakeAll"
        >
          <span>UNSTAKE ALL</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
//import type { Nft, UnbondingNft } from "@/types";

export default {
  props: {
    globalStats: {
      type: Object,// as () => UnbondingNft[],
      default() {
        return {};
      },
    },
    readyToClaim: {
      type: Object,// as () => UnbondingNft[],
      default() {
        return {};
      },
    },
    unbonding: {
      type: Object,
      default() {
        return {};
      },
    },
    userBalance: {
      type: Object,
      default() {
        return {};
      },
    },
    rewards: {
      type: String,
      default() {
        return "0";
      },
    },
    earning: {
      type: String,
      default() {
        return "0";
      },
    },
    balance: {
      type: String,
      default() {
        return "0";
      },
    },
    actualperiod: {
      type: Number,
      default() {
        return 0;
      },
    },
    individual: {
      type: Object,
      default() {
        return {};
      },
    },
    fullPacks: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
    alphaPacks: {
      type: Object,// as () => Nft[],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      nftArray: [], // NFTs taken from api
      isUnbounding: false, // switch for button
    };
  },
  methods: {
    showModal() {
      this.$emit("show-staking-modal", true);
    },
    claimNfts(){
      this.$emit("claim-nfts");
    },
    claimRewards(){
      this.$emit("claim-rewards");
    },
    stakeAll(){
      this.$emit("stake-all");
    },
    unstakeAll(){
      this.$emit("unstake-all");
    },
    checkBalance(){
      let tot_length=this.userBalance.wolves.length+this.userBalance.werewolves.length+this.userBalance.ravens.length+this.userBalance.ultra.length+this.userBalance.bones.length
      console.log("check_balance");
      return tot_length>0;
    },
  },
};
</script>
