<template>
  <section id="nft">
    <div class="nft-content-item nft-base-wrapper">
      <div
        class="nft-image-wrapper nft-base-image"
        @click="toggleSelection"
        :class="{ 'nft-base-image-on-select': isSelected }"
      >
        <div class="image-container">
          <img :src="nft.media" alt="nft" class="nft-image" />
        </div>
        <div
          v-if="isSelected"
          class="box-minus"
          :class="{ 'show-box-minus': !showBoxPlus }"
        >
          <span></span>
        </div>
        <div
          v-if="!isSelected"
          class="box-plus"
          :class="{ 'show-box-plus': showBoxPlus }"
        >
          <span></span>
        </div>
        <div class="box-check" :class="{ 'box-selected': isSelected }">
          <span></span>
        </div>
        <div class="box-wrapper">
          <div class="box">
            <p>{{ nft.nft_type.name.toUpperCase() }}</p>
          </div>
        </div>
      </div>
      <p class="nft-name">
        {{
          nft.nft_type.name === "Ultra"
            ? nft.name
            : nft.nft_type.name.includes("X")
            ? nft.name
            : nft.ticker.split("-")[0] + "#" + nft.name
        }}
      </p>
    </div>
  </section>
</template>

<style lang="scss">
@import "./BalanceCard.scss";
</style>

<script lang="js">
export default {
  props: {
    nft: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isSelected: false, // switch for button
      showBoxPlus: false, // switch for box-plus
    }
  },
  methods: {
    getUltraMedia(name) {
      if (name === "Ultra Beni Werewolf") {
        return "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/21.png";
      }
    },
    toggleSelection() {
      this.isSelected = !this.isSelected;
      if (this.isSelected) {
        this.$emit("nft-selected", this.nft);
      }
      else if (!this.isSelected){
        this.$emit("nft-unselected", this.nft);
      }
    },
  },
};
</script>
<style>
.image-container {
  mix-blend-mode: lighten;
}
</style>
