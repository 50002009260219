import { bech32 } from "bech32";
const api = "https://devnet-api.multiversx.com/";

const bech32Encode = (address: any) => {
  if (!address?.length) return;
  const pubKey = Buffer.from(address, "hex");
  const words = bech32.toWords(pubKey);
  return bech32.encode("erd", words);
};

const sleep = (n: number) => {
  return new Promise((resolve) => setTimeout(resolve, n));
};
const round = (x: number, to: number): number => {
  return Math.round(x * to) / to;
};

const rand = (from: number, to: number): number => {
  return Math.floor(Math.random() * (to - from) + from);
};

const intToHex = (str: string): string => {
  let hex = Number(str).toString(16);
  if (hex.length % 2 !== 0) {
    hex = `0${hex}`;
  }
  return hex;
};

const strToHex = (str: string): string => {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  if (result.length % 2 !== 0) {
    result = `0${result}`;
  }
  return result;
};

export { round, rand, intToHex, strToHex, sleep, bech32Encode, api };
