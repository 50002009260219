
import Staking from "@/pages/staking/Staking.vue";
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";

export default {
  components: {
    Staking,
    Header,
    Footer,
  },
};
