<template>
  <section id="nft">
    <div class="nft-content-item nft-base-wrapper">
      <div
        class="nft-image-wrapper nft-base-image"
        @click="toggleSelection"
        :class="{ 'nft-base-image-on-select': isSelected }"
      >
        <div class="image-container">
          <img
            v-if="
              nft.nft_type.name == 'Wolf' ||
              nft.nft_type.name == 'Raven' ||
              nft.nft_type.name == 'Og'
            "
            :src="media[nft.nft_type.name] + nft.nonce + '.png'"
            alt="nft"
            class="nft-image"
          />
          <img
            v-else-if="nft.nft_type.name == 'L1' || nft.nft_type.name == 'L2'"
            :src="media[nft.nft_type.name] + nft.name + '.png'"
            alt="nft"
            class="nft-image"
          />
          <img
            v-else-if="nft.nft_type.name === 'Bone'"
            :src="require('@/assets/goldebone.gif')"
            :style="'padding: 20px'"
            alt="nft"
            class="nft-image"
          />
          <img v-else :src="nft.media" alt="nft" class="nft-image" />
        </div>
        <div
          v-if="isSelected"
          class="box-minus"
          :class="{ 'show-box-minus': !showBoxPlus }"
        >
          <span></span>
        </div>
        <div
          v-if="!isSelected"
          class="box-plus"
          :class="{ 'show-box-plus': showBoxPlus }"
        >
          <span></span>
        </div>
        <div class="box-check" :class="{ 'box-selected': isSelected }">
          <span></span>
        </div>
        <div class="box-wrapper">
          <div class="box">
            <p>{{ nft.nft_type.name.toUpperCase() }}</p>
          </div>
        </div>
      </div>
      <p class="nft-name">
        {{
          nft.nft_type.name === "Ultra"
            ? nft.name
            : nft.nft_type.name === "Bone"
            ? nft.name
            : nft.ticker.split("-")[0] + "#" + nft.name
        }}
      </p>
    </div>
  </section>
</template>

<style lang="scss">
@import "./StakedCard.scss";
</style>

<script lang="js">
export default {
  props: {
    nft: {
      type: Object,
      default() {
        return {};
      },
    },
    unstakeArray: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isSelected: false, // switch for button
      showBoxPlus: false, // switch for box-plus
      media: {
        Og: "https://media.elrond.com/nfts/asset/QmemHXkBYZwUFZ4fMEgaTupzPS4PALnTmB7HEHSZm5Kt5J/",
        Wolf: "https://media.elrond.com/nfts/asset/QmemHXkBYZwUFZ4fMEgaTupzPS4PALnTmB7HEHSZm5Kt5J/",
        L1: "https://media.elrond.com/nfts/asset/QmXGPKjSn2huDEanmxrWq8WqiWvBgmQjTHqDsu1Qj6smuw/",
        L2: "https://media.elrond.com/nfts/asset/QmRGAUzxJ1aR55mWkuyFKCBLUEqGkwgc9zN57rcvwqxR3w/",
        Ultra:
          "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/",
        Raven:
          "https://media.elrond.com/nfts/asset/QmYjvaYuDjGBmqBgZAbK9dKzXxURpe1mXbLpyieJAkr71n/",
        Bone: "https://media.elrond.com/nfts/asset/QmWfXssATKGEZr4QuFyXXL3VApeHWowWuHrSQ8CmNCrLUS/goldebone.gif",
      }
    }
  },
  methods: {
    getUltraMedia(name) {
      if (name === "Ultra Beni Werewolf") {
        return "https://media.elrond.com/nfts/asset/QmeDUiCP6fQiDwNBYBGhfSwNEYoAbEz1wQzjG1CU7Mmw8N/21.png";
      }
    },
    toggleSelection() {
      this.isSelected = !this.isSelected;
      this.$emit("nft-selected", this.nft, this.isSelected);
    },
  },
  watch: {
    unstakeArray: {
      handler(newVal, oldVal) {
        let isUnstaked = false;
          newVal?.forEach((nft)=>{
          if(nft.ticker === this.nft.ticker && nft.nonce === this.nft.nonce){
            isUnstaked = true;
          }
        });
        this.isSelected = isUnstaked;
      },
      deep: true, // set to true if unstakeArray is an object or array
    },
  },
};
</script>
<style>
.image-container {
  mix-blend-mode: lighten; /* apply mix-blend-mode style to the image container */
}
</style>
